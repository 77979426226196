import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './Pages/Home/Home';
// import About from './About';
// import Contact from './Contact';
import Deos from './Pages/Deos/Deos';  // Assuming you have a Deos component
// import NotFound from './NotFound';  // Assuming you have a NotFound component
import './App.css';

const App = () => {
  return (
    <Router>
      <div className="app-container">
       
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} /> */}
          <Route path="/deos" element={<Deos />} />
          {/* <Route path="*" element={<NotFound />} />  Catch-all route */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
