import React, { useState, useEffect } from 'react';
import CSVReader from 'react-csv-reader';
import Modal from 'react-modal';
import './Deos.css';

Modal.setAppElement('#root');

const App = () => {
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [carouselImages, setCarouselImages] = useState([]);
  const [visibleImages, setVisibleImages] = useState([]);
  const [imageLoading, setImageLoading] = useState(true);
  const [recordIndex, setRecordIndex] = useState(0);
  const [message, setMessage] = useState('');

  const handleFileUpload = (data) => {
    setData(data);
    setVisibleImages([]);
    setImageLoading(true);
    setRecordIndex(0);
  };

  useEffect(() => {
    if (data.length > 0) {
      let index = 0;
      const timer = setInterval(() => {
        if (index < data.slice(1).length) {
          setVisibleImages((prev) => [...prev, data.slice(1)[index]]);
          index++;
        } else {
          setImageLoading(false);
          clearInterval(timer);
        }
      }, 2); // 0.2 seconds delay
      return () => clearInterval(timer);
    }
  }, [data]);

  const openModal = (images, index) => {
    setCarouselImages(images);
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImageIndex(0);
    setCarouselImages([]);
  };

  const resetData = () => {
    setData([]);
    setVisibleImages([]);
    setImageLoading(true);
  };

  const nextImage = () => {
    if (selectedImageIndex < carouselImages.length - 1) {
      setSelectedImageIndex((prevIndex) => prevIndex + 1);
    } else {
      const newRecordIndex = recordIndex + 1;
      if (newRecordIndex < data.length - 1) {
        setRecordIndex(newRecordIndex);
        const newImages = data[newRecordIndex + 1][data[newRecordIndex + 1].length - 1].split(',').map(url => url.replace(/['\[\]]/g, ''));
        setCarouselImages(newImages);
        setSelectedImageIndex(0);
        showMessage(`Record ID changed from ${data[recordIndex + 1][0]} to ${data[newRecordIndex + 1][0]}`);
      }
    }
  };

  const prevImage = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex((prevIndex) => prevIndex - 1);
    } else {
      const newRecordIndex = recordIndex - 1;
      if (newRecordIndex >= 0) {
        setRecordIndex(newRecordIndex);
        const newImages = data[newRecordIndex + 1][data[newRecordIndex + 1].length - 1].split(',').map(url => url.replace(/['\[\]]/g, ''));
        setCarouselImages(newImages);
        setSelectedImageIndex(newImages.length - 1);
        showMessage(`Record ID changed from ${data[recordIndex + 1][0]} to ${data[newRecordIndex + 1][0]}`);
      }
    }
  };

  const showMessage = (msg) => {
    setMessage(msg);
    setTimeout(() => setMessage(''), 3000); // Remove the message after 3 seconds
  };

  return (
    <div className="Deos-app-container">
      <header className="Deos-header">
        <h1>CSV Image Table</h1>
        <div className="Deos-header-actions">
          <CSVReader onFileLoaded={handleFileUpload} />
          <button onClick={resetData} className="Deos-reset-button">Reset</button>
        </div>
      </header>

      {data.length > 0 && (
        <table className="Deos-data-table">
          <thead>
            <tr>
              <th>Record ID</th>
              <th>Images</th>
            </tr>
          </thead>
          <tbody>
            {visibleImages.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>{row[0]}</td>
                <td className="Deos-image-cell">
                  {row[row.length - 1].split(',').map((imageUrl, index) => (
                    <div
                      key={index}
                      className="Deos-image-container"
                    >
                      <img
                        src={imageUrl.replace(/['\[\]]/g, '')}
                        alt={`Image ${index + 1}`}
                        className="Deos-thumbnail"
                        onClick={() => openModal(row[row.length - 1].split(',').map(url => url.replace(/['\[\]]/g, '')), index)}
                      />
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="Deos-modal" overlayclassName="Deos-overlay">
        <div className="Deos-modal-content">
          <div className="Deos-modal-header">
            <span className="Deos-record-id">Record ID: {data[recordIndex + 1]?.[0]}</span>
            <button onClick={closeModal} className="Deos-close-button">Close</button>
          </div>
          <div className="Deos-modal-body">
            {carouselImages.length > 0 && (
              <>
                <img src={carouselImages[selectedImageIndex]?.replace(/['\[\]]/g, '')} alt="Selected" className="Deos-modal-image" />
                <div className="Deos-product-name">
                  <span className="Deos-record-id">Product Name: {carouselImages[selectedImageIndex]?.split("/")[3]?.replace("-", " ").replace(".jpg", "")}</span>
                </div>
              </>
            )}
            </div>
          <div className="Deos-modal-footer">
            <button onClick={prevImage} className="Deos-nav-button prev-button">Prev</button>
            <button onClick={nextImage} className="Deos-nav-button next-button">Next</button>
          </div>
        </div>
      </Modal>

      {message && <div className="Deos-message">{message}</div>}
    </div>
  );
};

export default App;

